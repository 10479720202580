/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'm47zV2FuiEyRLiAoX7uGwp.png': { uri: '/images/m47zV2FuiEyRLiAoX7uGwp.png' },
'u8wK6X6jCBhLC4d1yMdnz5.jpg': { uri: '/images/u8wK6X6jCBhLC4d1yMdnz5.jpg' },
'9H4zkE5w8dTmKjWHaTtamQ.png': { uri: '/images/9H4zkE5w8dTmKjWHaTtamQ.png' },
'ugE2G2LbvajhUQfiNoL1a2.png': { uri: '/images/ugE2G2LbvajhUQfiNoL1a2.png' },
'gyB1i4MKhcp4TfFt8f9EdF.png': { uri: '/images/gyB1i4MKhcp4TfFt8f9EdF.png' },
'2VvvGSYcGiu2ovoSKfhQVb.png': { uri: '/images/2VvvGSYcGiu2ovoSKfhQVb.png' },
'3HTUVinTQjfgtSnvRJ1foj.png': { uri: '/images/3HTUVinTQjfgtSnvRJ1foj.png' },
'aciwWEVJ68A3cqzRsY1Dbk.png': { uri: '/images/aciwWEVJ68A3cqzRsY1Dbk.png' },
'37Vj4ZtaehV3QvTB82mYE9.png': { uri: '/images/37Vj4ZtaehV3QvTB82mYE9.png' },
'w1NrfnswjZBQKBX7tYUX9G.png': { uri: '/images/w1NrfnswjZBQKBX7tYUX9G.png' },
'inx2Mo9eQsdXquk7J1oZvc.png': { uri: '/images/inx2Mo9eQsdXquk7J1oZvc.png' }
}

export default imageStaticSourcesByFileName
